<template>
  <div class="user_container">
    <el-row class="user_header">
      <el-col :span="24">挂号订单列表</el-col>
    </el-row>
    <el-row class="tip">
    </el-row>
    <Count></Count>
    <div class="user_main">
      <el-table :data="tableData" v-loading="loading" border="" height="98%" style="width: 100%;">
        <el-table-column label="就诊人" prop="name">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="医生" prop="doctorName">
          <template slot-scope="scope">
            <span>{{ scope.row.doctorName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="科室" prop="deptName">
          <template slot-scope="scope">
            <span>{{ scope.row.deptName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="就诊时间" prop="scheduleDate">
          <template slot-scope="scope">
            <span>{{ scope.row.scheduleDate }}</span><br>
            <span>{{ scope.row.beginTime }} ~{{ scope.row.endTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click.native="patRegReport(scope.row)"
              style="display:inline-block; width:80%;">报道</el-button>
            <el-button class="cancel" type="danger" @click.native="cancelReg(scope.row)"
              style="display:inline-block; width:80%;">取消</el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <div class="tip">
      <h1>温馨提示：默认查询7天内的挂号记录</h1>
      <h1>请将条形码对准扫码口！</h1>
    </div>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from "@/baseAPI";
export default {
  components: {
    Foo, Count
  },
  data () {
    return {
      date: '',
      end: '',
      query: {},
      tableData: [],
      loading: false
    }
  },
  created () {
    this.query = this.$route.query
    this.date = this.formatTime(-6)
    this.end = this.formatTime(0)
    this.recordList()

  },
  methods: {

    // 取消挂号
    cancelReg (row) {
      let _this = this
      let params = {
        appointmentId: row.appointmentId
      }
      Promise.all([_this.$api.unlockReg(params)]).then((data) => {
        if (data[0].code == 0) {
          this.$message({
            message: '您已取消挂号成功',
            type: 'warning'
          })
          this.recordList()
        } else {
          this.$message({
            message: data[0].msg,
            type: 'warning'
          })
        }
      });

    },
    // 报道
    patRegReport (row) {
      console.log(row);
      let _this = this
      const params = {
        appointmentId: row.appointmentId
      }
      Promise.all([_this.$api.patRegReport(params)]).then((data) => {
        console.log(data[0]);
        if (data[0].code == 0) {
          _this.$message({
            message: data[0].msg,
            type: 'success'
          })
          _this.hisQueueup(row.orderNum, row.deptCode, row.doctorCode)
          setTimeout(() => {
            this.$router.push('/home')
          }, 2000);
        } else {
          this.$message({
            message: data[0].msg,
            type: 'warning'
          })
        }
      })
    },
    async hisQueueup (orderNum, deptCode, doctorCode) {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const { data: res } = await jsonPost(
        "/appointmentRecord/record/hisQueueup",
        {
          departmentId: deptCode,
          doctorId: doctorCode,
          serialNumber: orderNum,
        }
      );
      if (res.code === 0) {
        let printNo = res.data.PrintNo
        let doctorCode = res.data.DoctorID
        let WaitCount = res.data.WaitCount
        let SerialNumber = res.data.SerialNumber
        // this.reportedReceipt(printNo, doctorCode, WaitCount, SerialNumber)
        loading.close()
      } else {
        loading.close()
      }
    },
    // 打印排队凭条
    // reportedReceipt (PrintNo, DoctorID, WaitCount, SerialNumber) {
    //   let _this = this
    //   const params = {
    //     SerialNumber: SerialNumber,
    //     PrintNo: PrintNo,
    //     DoctorID: DoctorID,
    //     WaitCount: WaitCount
    //   }
    //   Promise.all([_this.$api.reportedReceipt(params)]).then((data) => {

    //   });
    // },
    home () {
      this.$router.push('/home')
    },
    recordList () {
      this.loading = true
      let _this = this
      let params = {
        endTime: this.end,
        startTime: this.date,
        patCardNo: this.query.patCardNo,
        recordType: 1
      }
      Promise.all([_this.$api.recordList(params)]).then((data) => {
        // this.tableData = data[0].data
        this.loading = false
        this.tableData = data[0].data.filter((item) => item.isActive == 1)
      });
    },
    pay (row) {
      let _this = this
      const params = {
        name: "预约挂号",
        orderNum: row.orderNum,
        recordType: row.recordType,
        regFee: row.regFee,
        outTradeNo: row.outTradeNo,
        appointmentId: row.appointmentId
      }
      this.$router.push({
        path: '/mode',
        query: params
      })
    },
    cancel (row) {
      let _this = this
      let params = {
        appointmentId: row.appointmentId
      }
      Promise.all([_this.$api.unlockReg(params)]).then((data) => {
        this.$message({
          message: data[0].msg,
          type: 'success'
        })
        this.recordList()
        // setTimeout(() => {
        //   this.$router.push("/home")
        // }, 2000);
      });

    },
    formatTime (num) {
      var now = new Date();
      var nowTime = now.getTime();
      var oneDayTime = 24 * 60 * 60 * 1000;
      var ShowTime = nowTime + num * oneDayTime;
      var myDate = new Date(ShowTime);
      var y = myDate.getFullYear(); //年
      var m = (myDate.getMonth() + 1) < 10 ? ("0" + (myDate.getMonth() + 1)) : myDate.getMonth() + 1; //月
      var d = myDate.getDate() < 10 ? ("0" + myDate.getDate()) : myDate.getDate(); //日
      return [y, m, d].map(this.padStartConvert).join('-');
    },
    padStartConvert (n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    }
  }
}
</script>
<style >
.el-message {
  /* top: 10% !important; */
  width: 70%;
  height: 100px;
  text-align: center !important;
  font-size: 50px !important;
}

.user_container {
  width: 100%;
  height: 100%;
}

.user_header {
  background-color: #81ace4;
  height: 60px;
  font-size: 32px;
  font-weight: 700;
  line-height: 60px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.tip h1 {
  margin-top: 20px;
  font-weight: 400;
  color: red;
  text-align: center;
  font-size: 34px;
}

.user_main {
  width: 90%;
  height: 70%;
  margin: 0 auto;
}

.user_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.user_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
}

.user_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}

.cancel {
  margin-top: 15px;
}
</style>